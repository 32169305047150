/* /////////////////////////////////////////////////////////
 * Tools | Animations
 * /////////////////////////////////////////////////////////*/

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }


/* /////////////////////////////////////////////////////////
 * Base
 * /////////////////////////////////////////////////////////*/

h1, h2, h3, h4 {
  margin: 1.414em 0 0;
}

h1 {
  margin-top: 0;
  font-size: ms(4);
}

h2 {font-size: ms(3);}

h3 {font-size: ms(2);}

h4 {font-size: ms(1);}

h4, h5, h6 {line-height: inherit;}

small, .font_small {font-size: 0.8em;}

body {
    background: $black;
}

ul {
    list-style: circle;
}

p + ul {
    margin-top: -1.3em;
}

*:focus {
    outline: none;
}



/* /////////////////////////////////////////////////
 * Mobile-menu
 * //////////////////////////////////////////////*/

.mobile-menu {
    position: fixed;
    background: $white;
    z-index: 1000;
    left: 0;
    width: 100%;

    @include breakpoint(medium) {
        display: none;
    }
}

.mobile-menu-spacer{
    height: 3rem;

    @include breakpoint(medium) {
        display: none;
    }
}

.mobile-menu__btn {
    padding: 1rem;
    color: $black;

    &:focus {
        outline: 0;
    }
}

.mobile-menu__list {
    display: none;
    margin: 0;
    padding-left: 1rem;
    padding-bottom: 1rem;
    list-style: none;
}

.mobile-menu__link {

}

/* /////////////////////////////////////////////////
 * Header
 * //////////////////////////////////////////////*/

.site-header {
    padding: 0 1rem;
    background: $black;
    transition: background 0.3s ease;

    @include breakpoint(large) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background: none;
        z-index: $zindexHeader;
    }
}

.site-header {
    will-change: transform;
    transition: transform 200ms linear;
}

.site-header--pinned {
    background: rgba(0,0,0,0.8);
    transform: translateY(0%);
}

.site-header--unpinned {
    transform: translateY(-100%);
}

.site-header__logo {
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;
}

.site-header__menu {
    @include grid-column(10);
    background: $black;

    @include breakpoint(large) {
        background: none;
    }
}

.site-header__list {
    display: none;
    margin: 0;
    padding: 0;

    @include breakpoint(medium) {
        display: block;
        margin-bottom: 1rem;
    }

    @include breakpoint(large) {
        display: inline-block;
        margin-bottom: 0;
    }
}

.site-header__item {
    display: inline-block;
}

.site-header__link {
    display: block;
    color: $white;
    margin-right: 1em;
    opacity: 0.9;
    &:hover, &:focus {
        color: $white;
        opacity: 1;
    }
}

.site-header__link--active {
    border-bottom: 2px solid;
}

.site-header__langs {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-right: 1rem;
    list-style: none;
    font-size: ms(-2);
    text-transform: uppercase;
    vertical-align: middle;
}

.site-header__lang {
    display: inline-block;
    margin-right: 0.5em;
    border: 1px solid transparent;
    border-radius: 100%;
    width: 1.8em;
    height: 1.8em;
    text-align: center;
}

.site-header__lang-link {
   color: $white;
    &:hover, &:focus {
        color: $white;
    }
}

.it .it, .en .en {
    border-color: $white;
}

/* /////////////////////////////////////////////////////////
 * Main
 * /////////////////////////////////////////////////////////*/

.main {
    @include breakpoint(large) {
        margin-left: 25rem;
        overflow: hidden;
        background: $secondary-color;
    }
}

.main__content {
    padding-top: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 4rem;
    background: $white;

    @include breakpoint(large) {
        @include grid-column;
        margin-top: 6rem;
        padding-top: 4.75rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    h1 {
        margin-bottom: 1em;
        font-size: ms(4);
        line-height: 1.2;
    }
    a {
        color: #006da3;
        text-decoration: underline;
        font-weight: 500;
        &:hover {
            color: $black;
        }
    }
    p, h4, h5, h6 {
        max-width: 30em;
    }
    em, i {
        color: $dark-gray;
    }
    img {
        margin-bottom: 0.5rem;
        padding-top: 0.25rem;
    }
    iframe {
        width: 100%;
        border: 1px solid lightgrey;
        height: 20rem;
    }
    table {
        width: 100%;
        font-size: ms(-1);
    }
    th, td {
        text-align: left !important;
    }
}


/* /////////////////////////////////////////////////////////
 * Footer
 * /////////////////////////////////////////////////////////*/

.site-footer {
    padding: 1rem;
    background: $black;
    font-size: ms(-1);

    @include breakpoint(large) {
        position: fixed;
        left: 0;
        width: 25rem;
        bottom: 0;
        padding: 0.5rem 2rem;
        z-index: $zindexFooter;
    }
}

.site-footer__list{
    margin: 0;
    padding: 0;
}

.site-footer__item {
    display: inline-block;
}

.site-footer__link {
    display: block;
    margin-right: 1em;
    color: $white;
    opacity: 0.9;

    &:hover, &:focus {
        color: $white;
        opacity: 1;
    }
}

.site-footer__link--active {
    border-bottom: 2px solid;
}

.site-footer__social {
    padding-top: 0.25rem;
}

.site-footer__icon {
    margin-right: 0.25rem;
}


/* /////////////////////////////////////////////////
 * Sidebar
 * //////////////////////////////////////////////*/

.sidebar {
    padding: 1rem;
    background: $black;
    color: $white;

    @include breakpoint(large) {
        position: fixed;
        z-index: $zindexSidebar;
        top: 0;
        bottom: 8.5rem;
        overflow: auto;
        left: 0;
        width: 25rem;
        padding: 20vh 2rem 0;
    }
}

.sidebar__main-title {
    margin: 0 0 1rem;
    font-size: ms(3);
    letter-spacing: 0.02em;
    text-overflow: ellipsis;
    overflow: hidden;

    @include breakpoint(large) {
        margin: 0 0 2rem;
        font-size: ms(4);
    }
}

.sidebar__actions {
    display: flex;

    @include breakpoint(large) {
         display: block;
    }
}

.sidebar__action {
    margin: 0;
    margin-right: 0.5em;
    padding: 4px;
    font-weight: 400;
    font-size: 1rem;
    opacity: 0.9;
    cursor: pointer;
    transition: opacity 0.2s ease;

    &:hover {
        opacity: 1;
    }

    @include breakpoint(large) {
        display: block;
        font-size: ms(1);
        letter-spacing: 0.04em
    }
}

.sidebar__content {
    @include breakpoint(large) {
        height: 100%;
    }
}

.sidebar__link {
    position: relative;
    padding: 0.375rem 0 !important;
    color: $white;
    line-height: 1.2 !important;
    opacity: 0.9;

    &:hover, &:focus {
        color: $white;
        opacity: 1;
    }
}

.sidebar__link--active {
    &:before {
        content: '\29BF';
        position: absolute;
        margin-left: -16px;
        font-size: 18px;
        top: 50%;
        transform: translateY(-50%);
    }
}


/* /////////////////////////////////////////////////
 * Slideshow
 * //////////////////////////////////////////////*/

.slideshow-container {
    @include breakpoint(large) {
        position: absolute;
        z-index: $zindexSlideshow;
        top: 0;
        bottom: 0;
        overflow: hidden;
        left: 25rem;
        right: 0;
    }
}

.slideshow__item {
    position: relative;
    background-color: $black;
    background-image: url("../img/stilllife02.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 60vh;

    @include breakpoint(large) {
        height: 100vh;
    }
}

.slideshow__dida {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem 1rem 0.75rem;
    //background: #fff;
}

.slideshow__dida-text {
    //display: inline-block;
    margin-bottom: 0;
    margin-top: 0;
    color: $white;
    font-size: ms(-1);
    line-height: inherit;
    vertical-align: middle;
}

.slideshow__info-primary {
    display: none;

    @include breakpoint(medium) {
        display: inline-block;
    }
}

.slideshow__item1 {
    background-image: url("../img/image19.jpg");
}

.slideshow__item2 {
    background-image: url("../img/Gatefold.jpg");
}

.slideshow__item3 {
    background-image: url("https://source.unsplash.com/random");
}

.slick-arrow {
    position: absolute;
    z-index: 1000;
    top: 50%;
    background: $secondary-color;
    padding: 0.5rem 0.8rem;
    border-radius: 0;
    transform: translateY(-50%);
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 0;
}

.main .slick-slide img {
    max-height: 80vh;
    margin: auto;
}

/* /////////////////////////////////////////////////////////
 * Cookie banner
 * /////////////////////////////////////////////////////////*/

 .cc-cookie {
    display: none;
    margin-top: 0.25rem;
    color: $white;
} 
.cc-link {
    color: $white;
    text-decoration: underline;
    &:hover , &:focus {
        color: $white;
    }
}
 
/*.cc-cookie {
    display: none;
    font-size: ms(-1);
}

.cc-window {
    display: block;
}
.cc-window.cc-invisible {
    display: none;
}

.cc-window {
    position: fixed;
    overflow: hidden;
    box-sizing: border-box;
    line-height: 1.5em;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    z-index: 9999;
    background: $light-gray;
    color: $black;

}
.cc-window.cc-banner {
    padding: ms(0) ms(1);
    width: 100%;
    -ms-flex-direction: row;
    flex-direction: row
}
.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
    cursor: pointer
}
.cc-link {
    padding: 0.125em 0;
}
.cc-btn {
    text-align: center;
    white-space: nowrap;
}
.cc-bottom {
    bottom: 1em
}
.cc-window.cc-banner {
    -ms-flex-align: center;
    align-items: center
}
.cc-banner.cc-bottom {
    left: 0;
    right: 0;
    bottom: 0
}
.cc-banner .cc-message {
    padding-right: 10px;
    -ms-flex: 1;
    flex: 1
}
.cc-compliance {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: justify;
    align-content: space-between
}
.cc-compliance>.cc-btn {
    -ms-flex: 1;
    flex: 1
}
.cc-btn+.cc-btn {
    margin-left: .5em
}
@media print {
    .cc-revoke,
    .cc-window {
        display: none
    }
}
@media screen and (max-width: 900px) {
    .cc-btn {
        white-space: normal
    }
}
@media screen and (max-width: 414px) and (orientation: portrait),
screen and (max-width: 736px) and (orientation: landscape) {
    .cc-window.cc-top {
        top: 0
    }
    .cc-window.cc-bottom {
        bottom: 0
    }
    .cc-window.cc-banner,
    .cc-window.cc-left,
    .cc-window.cc-right {
        left: 0;
        right: 0
    }
    .cc-window.cc-banner {
        -ms-flex-direction: column;
        flex-direction: column
    }
    .cc-window.cc-banner .cc-compliance {
        -ms-flex: 1;
        flex: 1
    }
    .cc-window.cc-floating {
        max-width: none
    }
    .cc-window .cc-message {
        margin-bottom: 1em
    }
    .cc-window.cc-banner {
        -ms-flex-align: unset;
        align-items: unset
    }
}*/


/* /////////////////////////////////////////////////////////
 * Utils
 * /////////////////////////////////////////////////////////*/

.is-playing {
    display: block;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

/* -----
SVG Icons - svgicons.sparkk.fr
----- */

.svg-icon {
  width: 1.2em;
  height: 1.2em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: $white;
}

.svg-icon circle {
  stroke: $white;
  stroke-width: 1;
}
